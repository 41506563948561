import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Project from '../components/project';

export const query = graphql`
    query($slug: String!) {
        contentfulProject(slug: {eq: $slug}) {
            slug
            tags
            title
            category
            childContentfulProjectDescriptionRichTextNode {
                json
            }
            images {
                id
                fluid (maxWidth: 3000,quality:100) {
                  ...GatsbyContentfulFluid
                }
              }
            videoSrc
        }
    }
`

const ProjectTemplate = ({ data }) => {
    const project = data.contentfulProject;

    const tags = project.tags;
    const title = project.title;
    const description = project.childContentfulProjectDescriptionRichTextNode.json;
    const url = project.url;
    const category = project.category;
    const imageData = project.images;
    const videoData = project.videoSrc;


    return (
        <Layout>
            <Project
                title={title}
                description={description}
                url={url}
                imageData={imageData}
                videoData={videoData}
                tags={tags}
                category={category}
            />
        </Layout>
    )
}

export default ProjectTemplate;